import { React, useEffect, useState, api, API_URL, PuffLoader, override, toast } from "../../../services/common";
import { Card } from "react-bootstrap";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Rating } from "@mui/material";
import { useParams } from 'react-router-dom';
import reviewimage from "../../../assets/images/review-image.jpg"
import classcount from "../../../assets/images/classcount.svg";
import studentcount from "../../../assets/images/student-count.svg";
import levels from "../../../assets/images/levels.svg";
import { useNavigate } from 'react-router-dom';
import profile from "../../../assets/images/profile.svg";
import MainHeader from "../../Common/Header/MainHeader/MainHeader";
import "./TutorDetails.css";


const TutorDetails = (props) => {
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tutor, setTutor] = useState([]);
    const [courses, setCourses] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [avgRating, setAvgRating] = useState(0);
    const { tutorId } = useParams();
    const navigate = useNavigate();

    const fetchTutor = () => {
        setLoading(true);
        api.get(API_URL + `/tutors/view-tutor/` + tutorId)
            .then((response) => {
                setLoading(false);
                setTutor(response.data);
                const reviews = response.data?.reviews;
                setCourses(response.data?.courses);
                setReviews(reviews);
                const rating = calculateAverageRating(reviews);
                setAvgRating(rating);
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.message || error.message); ;
                console.log(error)
            });
    }

    const viewSelectedCourse = (course, courseName) => {
        navigate(`/tutors/${tutorId}/${course}`, {state: {courseName: courseName}});
    }

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    const calculateAverageRating = (reviews) => {
        if (!reviews) {
            return 0; // or handle the case when there are no reviews
        }
        const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
        const averageRating = totalRating / reviews.length;
        return Number(averageRating % 1 !== 0 ? averageRating.toFixed(2) : averageRating);
    };

    useEffect(() => {
        fetchTutor();
    }, []);

    return (
        <>
            <MainHeader tutorName={tutor.name}></MainHeader>
            <div className="course-banner tutor-banner">
                <div className="row">
                    <div className="col-8">
                        <div className="tutor-details-banner">
                            <span className="course-title-details">{tutor?.name}</span>
                            <div className="tutor-stats">
                                
                            {tutor?.student_count &&  <span className="course-stats">
                                    <img className="icon-course-stats" src={studentcount} alt="Icon for Students"></img>
                                    Trained over  {tutor?.student_count} Students
                                </span> }

                                <span className="course-stats">
                                    <img className="icon-course-stats" src={levels} alt="Icon for Levels"></img>
                                    {tutor.experience} Years of Experience
                                </span>
                                <span className="course-stats">
                                    <svg className="icon-course-stats" xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                        <path d="M6.99967 0.833252C3.33301 0.833252 0.333008 3.83325 0.333008 7.49992C0.333008 11.1666 3.33301 14.1666 6.99967 14.1666C10.6663 14.1666 13.6663 11.1666 13.6663 7.49992C13.6663 3.83325 10.6663 0.833252 6.99967 0.833252ZM9.79967 10.2999L6.33301 8.16659V4.16659H7.33301V7.63325L10.333 9.43325L9.79967 10.2999Z" fill="white" />
                                    </svg>
                                    {tutor?.courses?.length} Courses
                                </span>
                            </div>
                        </div>
                    </div>

                    {tutor?.image && ( <div className="col-4 course-sidebar course-image-section">
                        <img className="tutor-details-image" src={tutor?.image} alt="Course Details of our e learning platform"></img>
                    </div>)}
           </div>

            </div>
            <div className="row">
                <div className="col-8 course-list-tutors">
                <p className = "courses-by">Courses by <span className = "course-by-tutor">{tutor.name}</span></p>
                    <div className="tutor-course-list">
                        {courses.map((course, index) => (
                            <div key={index}>
                                <Card className="tutor-course-cards" onClick={() => viewSelectedCourse(course?.id, course?.name)}>
                                    <Card.Img className="tutor-course-image" variant="left" src={course?.image || 'https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/placeholder.jpg?alt=media&token=f59e4d05-d1e7-4d92-bffa-c27b1ca6941b'} />
                                    <div>
                                    <Card.Body>
                                        <Card.Title className="tutor-name">
                                            {course.name}
                                        </Card.Title>
                                        <Card.Title >
                                            <img className="tutor-icons student-icon" src={classcount} alt = "Class count"></img>
                                            <span className="tutor-details">
                                            {course?.description && course.description.length > 15 ?
                                                    `${course.description.substring(0, 15)}...` :
                                                    course.description || `Learn ${course?.name}`}
                                            </span>
                                        </Card.Title>
                                        <Card.Title>
                                            <img className="tutor-icons student-icon" src={profile} alt = "Profile"></img>
                                            {course?.students && <span className="tutor-details">{course?.students} students</span>}
                                            {!course?.students && <span className="tutor-details">{course?.students} New Course</span>}
                                        </Card.Title>
                                    </Card.Body>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-4 course-info-tutor" >
                    <div className="course-tabs-details">
                        <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
                            <Tab label="Overview" />
                            <Tab label="Reviews" />
                        </Tabs>
                        <div className=" course-section">
                            {tab === 0 && <Typography>{tutor.about}</Typography>}
                            {tab === 1 && <>
                                <>
                                <div className="flex current-rating">
                                    <span className="avg-rating">{avgRating}</span>
                                    <div>
                                        <Rating name="read-only" value={avgRating} readOnly />
                                        <div className="base-rating">
                                            based on {reviews?.length} ratings
                                        </div>
                                    </div>
                                </div>

                                {reviews && reviews.map((review, index) => (
                                    <div key = {index} className="add-review">
                                        <div className="flex">
                                            <img className="review-image" src={review?.image || reviewimage} alt="Current Review Poster"></img>
                                            <div className = "w-100">
                                                <div className="flex-align">
                                                    <span className="review-name">{review.name}</span>
                                                    <span className="review-date">{review?.reviewDate}</span>
                                                </div>
                                                <div className="review-date">
                                                    {review.review}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
        </>

    )
}

export default TutorDetails;