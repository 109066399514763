import { React, useEffect, useState, api, API_URL, Modal, PuffLoader, override, toast } from "../../../services/common";
import { Card } from "react-bootstrap";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import { Rating } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useParams, useLocation } from 'react-router-dom';
import reviewimage from "../../../assets/images/review-image.jpg"
import studentcounticon from "../../../assets/images/student-purple.svg";
import classcount from "../../../assets/images/classcount.svg";
import studentcount from "../../../assets/images/student-count.svg";
import even from "../../../assets/images/even.svg";
import { useNavigate } from "react-router-dom";
import profile from "../../../assets/images/profile.svg";
import MainHeader from "../../Common/Header/MainHeader/MainHeader";
import Payment from "../Payment/Payment";
import "./CourseDetails.css"
import * as TableMaterialUI from '@mui/material';
import Paper from '@mui/material/Paper';
import plusgrey from "../../../assets/images/plus-grey.svg";
import crossicon from "../../../assets/images/cross-icon.svg";
import info from "../../../assets/images/info.svg";

const { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } = TableMaterialUI;


const CourseDetails = (props) => {
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tutor, setTutor] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [reviews, setReviews] = useState([]);
    const [avgRating, setAvgRating] = useState(0);
    const location = useLocation();
    const [courses, setCourses] = useState([]);
    const [demoModal, setDemoModal] = useState(false);
    const [startModal, setStartModal] = useState(false);
    let courseName = location.state?.courseName || null;
    const [selectedSlot, setSelectedSlot] = useState('');
    const [availableSlots, setAvailableSlots] = useState([])
    const [courseSlots, setCourseSlots] = useState([]);
    const [showCourseSlots, setShowCourseSlots] = useState(false);
    const [selectedSlots, setSelectedSlots] = useState({});
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [amount, setAmount] = useState(2400)
    const [maxSlots, setMaxSlots] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [classCount, setClassCount] = useState(8);
    const [open, setOpen] = useState(false);
    const [recurring, setRecurring] = useState(true);
    const [validity, setValidity] = useState();
    const [startNow, setStartNow] = useState(true);
    const [demoClass, setDemoClass] = useState(true);
    const buffer = 2;
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    let id = localStorage.getItem('uid');



    const { tutorId, course } = useParams();
    const navigate = useNavigate();


    const changeClasses = (e) => {
        const count = e.target.value;
        setClassCount(count)
        setAmount(count * 300);
        setValidity(Math.ceil(count / totalCount) + buffer)
    }

    const fetchTutor = async () => {
        try {
            setLoading(true);
            const response = await api.get(API_URL + `/tutors/view-tutor/` + tutorId)
            setLoading(false);
            setTutor(response.data);
            await courseData(response.data);
        }
        catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || error.message); ;
            console.log(error)
        };
    }

    const continueAction = () => {
        if (!localStorage.getItem('uid')) {
            toast.error("Login to Continue")
        }
        else {
            setStartModal(true)
        }
    }


    const bookDemoSchedule = () => {
        setLoading(true);
        let body = {
            slotId: selectedSlot,
            student: id,
            course: course,
            demo: true,
        }
        api.post(API_URL + "/tutors/book-schedule/" + tutorId, body).then((response) => {
            setLoading(false);
            toast.success("Demo Class Booked ! See you at the Demo, Redirecting to Student Dashboard");
            setTimeout(() => {
                navigate("/student-panel/dashboard");
            }, 3000);
        })
            .catch((error) => {
                setLoading(false);
                console.log(error)
                toast.error(error?.response?.data?.message || error.message); ;
            });
    }



    const viewSimilarCourses = async() => {
        try{
        setLoading(true);
        const response = await api.get(API_URL + '/tutors/view-tutors')
            setLoading(false);
            const tutorsData = response.data;
            const allCourses = tutorsData.reduce((courses, tutor) => {
                const tutorCourses = tutor.courses.map(course => ({
                    ...course,
                    tutor_id: tutor.id,
                    tutor: tutor.name
                }));
                return [...courses, ...tutorCourses];
            }, []);
            const remainingCourses = allCourses.filter(c =>
                !(c.tutor_id === tutorId && c.id === course)
            );
            let similarCourses;
            if (remainingCourses.length <= 3) {
                similarCourses = remainingCourses;
            } else {
                const course = selectedCourse.name || courseName
                // Step 3: Filter courses by search keyword
                similarCourses = remainingCourses.filter(c =>
                    c.name.toLowerCase().includes(course.toLowerCase())
                );
                // Step 4: Limit the final list to 3 courses if necessary
                if (similarCourses.length === 0) {
                    similarCourses = remainingCourses;
                }

                if (similarCourses.length > 3) {
                    similarCourses = similarCourses.slice(0, 3);
                }

            }
            setCourses(similarCourses);
        }catch(error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || error.message); ;
            console.log(error);
        };
    }

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    const viewSelectedCourse = async (tutorId, course) => {
        navigate(`/courses/${tutorId}/${course}`);
        await fetchTutor();
        await viewSimilarCourses();
    }

    const calculateAverageRating = (reviews) => {
        if (!reviews) {
            return 0; // or handle the case when there are no reviews
        }
        const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
        const averageRating = totalRating / reviews.length;
        return Number(averageRating % 1 !== 0 ? averageRating.toFixed(2) : averageRating);
    };

    const getTotalEntriesCount = (data) => {
        let totalEntries = 0;
        for (const day in data) {
            totalEntries += data[day].length;
        }
        return totalEntries;
    };

    const viewTutorSchedule = () => {
        if (courseSlots.length > 0) {
            setShowCourseSlots(true);
            return
        }
        setLoading(true);
        api.get(API_URL + "/tutors/view-weekly-schedule/" + tutorId).then((response) => {
            setLoading(false);
            setStartNow(false)

            const groupedData = daysOfWeek.reduce((acc, day) => {
                const dayData = response.data.find(item => item[day]);
                acc[day] = dayData ? dayData[day].schedule : [];
                // .filter(slot => !course || slot.course === course)
                return acc;
            }, {});
            setMaxSlots(Math.max(...daysOfWeek.map(day => (groupedData[day]?.length || 0))))
            setLoading(false);
            setCourseSlots(groupedData);
            setShowCourseSlots(true);
        })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error.message); ;
                setLoading(false);
                console.log(error);
            });
    }

    const closeDemoModal = () => {
        setDemoClass(true);
        setDemoModal(false);
    }

    const availableDemoSlots = () => {
        if (!localStorage.getItem('uid')) {
            toast.error("Login to Continue")
        }
        else {
            api.get(API_URL + "/tutors/view-available-schedule/" + tutorId + "?demo=true").then((response) => {
                setLoading(false);
                setAvailableSlots(response.data);
                setDemoModal(true);
                setDemoClass(false);
                setStartNow(true);
                setShowCourseSlots(false);
                if (!response.data.length) {
                    toast.error("No Slots Available")
                }
            })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error?.response?.data?.message || error.message); ;
                    console.log(error.message);
                });

        }
    }

    const removeSelectedSlots = () => {
        setSelectedSlots([]);
        setTotalCount(0);
    }

    const handleRadioChange = (day, slot) => {
        const slotId = slot.id;
        setSelectedSlots(prevSelectedSlots => {
            const isSelected = prevSelectedSlots[day]?.some(selectedSlot => selectedSlot.id === slotId);
            let updatedSlots
            if (isSelected) {
                updatedSlots = {
                    ...prevSelectedSlots,
                    [day]: prevSelectedSlots[day].filter(selectedSlot => selectedSlot.id !== slotId),
                };

            } else {
                const newSlot = {
                    id: slot.id,
                    start_time: slot.start_time,
                    end_time: slot.end_time,
                    course: course,
                    tutor_id: tutorId,
                };

                updatedSlots = {
                    ...prevSelectedSlots,
                    [day]: [
                        ...(prevSelectedSlots[day] || []),
                        newSlot
                    ],
                }
            }
            const count = getTotalEntriesCount(updatedSlots);
            setTotalCount(count)
            setValidity(Math.ceil(classCount / count) + buffer)
            return updatedSlots


        });

    };

    const handlePaymentSuccess = () => {
        setStartModal();
        toast.success("Success! Redirecting you to the Student Dashboard");
        setTimeout(() => {
            navigate("/student-panel/dashboard");
        }, 3000);
    }

    const handlePaymentFailure = (message) => {
        toast.error(message);
        setLoading(false);
    }


    const courseData = async (courseInfo) => {
        const currentCourse = courseInfo.courses.find(c => c.id === course);
        const reviews = courseInfo?.reviews?.filter(r => r.course === course).reverse();
        const updatedCourse = {
            ...currentCourse,
            reviews: reviews,
        };
        courseName = updatedCourse.name
        setReviews(reviews);
        const rating = calculateAverageRating(reviews);
        setAvgRating(rating);
        await setSelectedCourse(updatedCourse)
    }

    useEffect(() => {
        fetchTutor();
        viewSimilarCourses();
    }, []);

    return (
        <>
            <MainHeader tutorName={tutor.name} courseName={selectedCourse.name} ></MainHeader>

            <div className="course-banner">
                <div className="row">
                    <div className="col-7 col-width">
                        <div className="course-details-banner">
                            <span className="course-title-details">{selectedCourse.name}</span>
                            <div className="by-tutor">
                                By {tutor.name}
                            </div>
                            <span className="course-creds">

                                <span className="course-stats">
                                    <svg className="icon-course-stats" xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                        <path d="M6.99967 0.833252C3.33301 0.833252 0.333008 3.83325 0.333008 7.49992C0.333008 11.1666 3.33301 14.1666 6.99967 14.1666C10.6663 14.1666 13.6663 11.1666 13.6663 7.49992C13.6663 3.83325 10.6663 0.833252 6.99967 0.833252ZM9.79967 10.2999L6.33301 8.16659V4.16659H7.33301V7.63325L10.333 9.43325L9.79967 10.2999Z" fill="white" />
                                    </svg>
                                    {selectedCourse?.duration || "Flexible schedule"}
                                </span>
                                <span className="course-stats">
                                    <img className="icon-course-stats" src={studentcount} alt="Icon for Students"></img>
                                    {selectedCourse?.students} Students
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col-5 course-sidebar">
                        <div className="course-landing ">
                            {selectedCourse?.image && <img className="course-details-image" src={selectedCourse?.image} alt="Course Details of our e learning platform"></img>}
                            <div className="course-renew">
                                {demoClass && <Button onClick={availableDemoSlots} variant="contained" className="demo-class-button">Book Free Demo </Button>}
                                {startNow && <Button onClick={viewTutorSchedule} variant="contained" className="renew-now">Book Paid Class</Button>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="course-detail-section">
                {showCourseSlots && <div className="course-slots">
                    <div className="available-slots-title">Available Slots</div>
                    <TableContainer component={Paper}>

                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {daysOfWeek.map(day => (
                                        <TableCell className="slot-select week-day" key={day} align="center">{day}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.from({ length: maxSlots }).map((_, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {daysOfWeek.map(day => (
                                            <TableCell key={day} align="center" className="slot-select ">
                                                {courseSlots[day] && courseSlots[day][rowIndex] && <input className="slot-radio"
                                                    type="checkbox"
                                                    id={courseSlots[day][rowIndex]?.id}
                                                    value={courseSlots[day][rowIndex]?.id}
                                                    checked={selectedSlots[day]?.some(slot => slot.id === courseSlots[day][rowIndex]?.id)}
                                                    onChange={() => handleRadioChange(day, courseSlots[day][rowIndex])}
                                                />}


                                                {courseSlots[day][rowIndex]?.batch_limit && <span className="batch-size">
                                                    Batch of {courseSlots[day][rowIndex]?.batch_limit}
                                                </span>}
                                                {courseSlots[day] && courseSlots[day][rowIndex] ? (
                                                    <div>
                                                        <div className="available-time">{courseSlots[day][rowIndex].start_time} - {courseSlots[day][rowIndex].end_time}</div>
                                                        <div
                                                            className={`available-number ${courseSlots[day][rowIndex]?.batch_limit - courseSlots[day][rowIndex]?.students?.length < 3
                                                                ? 'limited'
                                                                : 'available'
                                                                }`}
                                                        >
                                                            {courseSlots[day][rowIndex]?.batch_limit &&
                                                                courseSlots[day][rowIndex]?.batch_limit > courseSlots[day][rowIndex]?.students?.length &&
                                                                `(${courseSlots[day][rowIndex]?.batch_limit - courseSlots[day][rowIndex]?.students?.length} Slots Available)`}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>}
                <div className="row">
                    <div className="col-6" >
                        <div className="course-info-details">
                            <div className="course-tabs-details course-tab-section">
                                <Tabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
                                    <Tab label="Overview" />
                                    <Tab label="Instructor" />
                                    <Tab label="Reviews" />
                                </Tabs>
                                <div className=" course-section course-info-section">
                                    {tab === 0 && <Typography>{selectedCourse.description}</Typography>}
                                    {tab === 1 && 
<>
                                        <div class = "flex">
                                            <img className="tutor-image-course" src={tutor?.image || 'https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/placeholder.jpg?alt=media&token=f59e4d05-d1e7-4d92-bffa-c27b1ca6941b'} alt="Tutor of the respective Course"></img>
                                            <div className="tutor-section-course">
                                                {tutor.name}
                                                <span>
                                                    <img src={studentcounticon} alt="Total Students enrolled for the course"></img>
                                                    <span className="student-count">{selectedCourse.students} students</span>
                                                </span>
                                            </div>
                                            </div>
                        
                                            <div className="about-tutor">
                                                {tutor.about}
                                            </div>
                                            </> 

                                    }
                                        
                                    {tab === 2 &&
                                    <>
                                        <div className="flex current-rating">
                                            <span className="avg-rating">{avgRating}</span>
                                            <div>
                                                <Rating name="read-only" value={avgRating} readOnly />
                                                <div className="base-rating">
                                                    based on {reviews?.length || 0} ratings
                                                </div>
                                            </div>
                                        </div>
                                        {reviews && reviews.map((review, index) => (
                                            <div key = {index} className="add-review">
                                                <div className="flex">
                                                    <img className="review-image" src={review?.image || reviewimage} alt="Current Review Poster"></img>
                                                    <div className="w-100">
                                                        <div className="flex-align">
                                                            <span className="review-name">{review.name}</span>
                                                            <span className="review-date">{review?.reviewDate}</span>
                                                        </div>
                                                        <div className="review-date">
                                                            {review.review}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className={` ${!showCourseSlots ? 'similar-courses' : ''} `}>
                            <p className="similar-text">
                                Similar Courses
                            </p>
                            <div className="tutor-list">
                                {courses.map((course, index) => (
                                    <div key={index}>
                                        <Card className="tutor-cards similar-course-container" onClick={() => viewSelectedCourse(course?.tutor_id, course?.id)}>
                                            <Card.Img className="tutor-image similar-course-image" variant="left" src={course?.image || 'https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/placeholder.jpg?alt=media&token=f59e4d05-d1e7-4d92-bffa-c27b1ca6941b'} />
                                            <Card.Body>
                                                <Card.Title className="tutor-name">
                                                    {course?.description && course.description.length > 25 ?
                                                        `${course.description.substring(0, 25)}...` :
                                                        course.description}
                                                </Card.Title>
                                                <Card.Title >
                                                    <img className="tutor-icons" src={profile} alt = "Tutor Profile Icon"></img>
                                                    <span className="tutor-details">{course?.tutor}</span>
                                                </Card.Title>
                                                <Card.Title>
                                                    <img className="tutor-icons student-icon" src={classcount} alt = "Student Count"></img>
                                                    <span className="tutor-details">{course?.students || 0} students</span>
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                    {totalCount > 0 &&
                        <div className="footer-slot">
                            <div>
                            <div className="select-slot-text">You have selected {totalCount} slots</div>
                            <div>*You need to be logged in to continue</div>
                            </div>

                        
                            <div>

                                <Button className="slot-continue" onClick={() => continueAction()}>Continue</Button>
                                <img onClick={removeSelectedSlots} className="cancel-continue" src={crossicon} alt = "Cross icon"></img>
                            </div>
                        </div>

                    }
                    <Modal show={demoModal} onHide={() => closeDemoModal()} dialogClassName="modal-40w">
                        <Modal.Header closeButton>
                            <b className="cancel-heading">Available Slots (for this week)</b>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="flex">
                                {availableSlots.map((slot) => (
                                    <Card className={`slot-card ${slot.id === selectedSlot ? 'selected-card' : ''}`} style={{ width: '10rem' }} onClick={() => setSelectedSlot(slot.id)}>
                                        <Card.Body>
                                            <Card.Title className="slot-date">{slot.date}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                ))
                                }
                            </div>
                            <Button className="join-class demo-class" onClick={bookDemoSchedule}>
                                Book Demo Class
                            </Button>
                        </Modal.Body>
                    </Modal>


                    <Modal show={startModal} onHide={() => setStartModal(false)} dialogClassName="modal-40w">
                        <Modal.Header closeButton>
                            <div className="begin-journey">
                                <img className="begin" src={even} alt = "begin your journey"></img>
                                Begin your Journey</div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="select-slot-text final-count">You have selected {totalCount} slots</div>
                            <div >
                                {daysOfWeek.map(day => (
                                    selectedSlots[day]?.length > 0 && ( // Check if selectedSlots[day] exists and has slots
                                        <div key={day} className="selected-slots flex">
                                            <div className="selected-day">{day}</div>
                                            {selectedSlots[day].map(slot => (
                                                <div className="selected-time" key={slot.id}>
                                                    {slot.start_time} - {slot.end_time}
                                                </div>
                                            ))}
                                        </div>
                                    )
                                ))}
                            </div>

                            <div className="more-slots">
                                Do you want to add more slot ?

                            </div>
                            <div>
                                <Button className="add-slot" onClick={() => setStartModal(false)}>
                                    <img className="plus-icon" src={plusgrey} alt = "icon to add a slot"></img>
                                    add slot</Button>
                            </div>


                            <hr />
                            <div>
                                <span>
                                    Number of Classes
                                </span>
                                <input type="number" min={totalCount} className="no-classes" value={classCount} onChange={(event) => changeClasses(event)} onBlur={(event) => {
                                    if (event.target.value < totalCount) {
                                        event.target.value = totalCount;
                                        changeClasses(event); 
                                    }
                                }}></input>
                            </div>

                            {totalCount < classCount && <div className="recurring">
                                <input type="checkbox" checked={recurring} onChange={(event) => setRecurring(event.target.checked)}>
                                </input>
                                <span className="recurring-text" > Do you want to make these slots recurring ?

                                    <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title="If you don't select recurring, you have to book the pending classes yourself">
                                        <img src={info} alt = "Information"></img>
                                    </Tooltip>
                                </span>
                            </div>}
                            <div className="flex align-center">
                                <Button className="cancel-pay-button" onClick={() => setStartModal(false)}> Cancel</Button>
                                <div className="payment-button">
                                    <Payment amount={amount} tutor_id={tutorId} slots={selectedSlots} classes={classCount} course={course} validity = {validity} onPaymentSuccess={handlePaymentSuccess} onPaymentFailure={handlePaymentFailure}></Payment>
                                </div>
                            </div>
                            <div className="validity">
                                These classes will be valid till for {validity} weeks from start date.
                            </div>


                        </Modal.Body>
                    </Modal>

                </div>
            </div>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
        </>




    )
}

export default CourseDetails;