import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Privacy Policy</h1>
      <p>Your privacy is important to us. This Privacy Policy explains how we handle your personal data.</p>
      
      <h2>1. Data Collection</h2>
      <p>
        We collect personal data such as your name, email address, and payment details when you use our services.
      </p>
      
      <h2>2. Data Usage</h2>
      <p>
        Your data is used to provide services, improve our platform, and process payments.
      </p>
      
      <h2>3. Data Sharing</h2>
      <p>
        We may share your data with trusted third-party service providers, such as payment gateways.
      </p>
      
      <h2>4. User Rights</h2>
      <p>
        You can request access, correction, or deletion of your personal data by emailing us at{' '}
        <a href="mailto:privacy@musicseekho.com" style={{ color: '#007bff', textDecoration: 'none' }}>
          privacy@musicseekho.com
        </a>.
      </p>
      
      <h2>5. Security</h2>
      <p>We use industry-standard measures to protect your data.</p>
      
      <p>
        For questions, contact us at{' '}
        <a href="mailto:support@musicseekho.com" style={{ color: '#007bff', textDecoration: 'none' }}>
          support@musicseekho.com
        </a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
