import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to Music Seekho! By accessing or using our website, you agree to the following Terms and Conditions. These terms ensure a safe and enjoyable experience for all users.
      </p>
      <h2>1. Use of Our Services</h2>
      <p>
        Our platform is designed to provide online music learning opportunities. Please use the services responsibly and refrain from activities that disrupt or harm the experience of other users.
      </p>
      <h2>2. Account Management</h2>
      <p>
        You are responsible for maintaining the confidentiality of your account information. Please notify us immediately if you suspect any unauthorized access or misuse of your account.
      </p>
      <h2>3. Intellectual Property</h2>
      <p>
        The content on Music Seekho, including lessons, videos, and materials, is our intellectual property. You may use the content for personal learning purposes but cannot reproduce, distribute, or use it commercially without our consent.
      </p>
      <h2>4. Service Availability</h2>
      <p>
        We strive to provide uninterrupted service but may need to perform maintenance or updates. In such cases, we will notify users in advance whenever possible.
      </p>
      <h2>5. Contact and Support</h2>
      <p>
        If you have any questions or need assistance, please contact us at{' '}
        <a href="mailto:support@musicseekho.com" style={{ color: '#007bff', textDecoration: 'none' }}>
          support@musicseekho.com
        </a>.
      </p>
      <h2>6. Changes to the Terms</h2>
      <p>
        We may update these terms periodically. Continued use of our services implies acceptance of the updated terms.
      </p>
    </div>
  );
};

export default TermsAndConditions;
