import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './index.css';
import { AuthProvider } from "./components/Auth/AuthContext"
import usePageTracking from './hooks/usePageTracking'; 
// Booking Flow
import Home from './pages/Home/Home';
import TutorsList from './components/BookClass/TutorList/TutorList';
import CourseList from './components/BookClass/CourseList/CourseList';
import TutorDetails from './components/BookClass/TutorDetails/TutorDetails';
import CourseDetails from './components/BookClass/CourseDetails/CourseDetails';
// Auth
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import PhoneAuth from './components/Auth/MobileLogin';
import ForgotPassword from './components/Auth/ForgotPassword';
// Student Panel 
import StudentDashboard from './components/StudentPanel/StudentDashboard/StudentDashboard';
import StudentSchedule from './components/StudentPanel/StudentSchedule/StudentSchedule';
import StudentHistory from './components/StudentPanel/StudentHistory/StudentHistory';
import StudentCourseDetails from './components/StudentPanel/StudentCourseDetails/StudentCourseDetails';
// Tutor Panel
import TutorDashboard from './components/TutorPanel/TutorDashboard/TutorDashboard';
import TutorSchedule from './components/TutorPanel/TutorSchedule/TutorSchedule';
import TutorHistory from './components/TutorPanel/TutorHistory/TutorHistory';
import TutorCourseDetails from './components/TutorPanel/TutorCourseDetails/TutorCourseDetails';
import reportWebVitals from './reportWebVitals';
import TermsAndConditions from './pages/Terms';
import RefundPolicy from './pages/Refund';
import PrivacyPolicy from './pages/Privacy';
import 'react-notifications/lib/notifications.css';



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(con

const App = () => {
  usePageTracking(); // Call the custom hook for page tracking

  return (
  <AuthProvider>
      
      <Routes>
        <Route index element={<Home />} />
        <Route path = "terms" element={<TermsAndConditions />}/>
        <Route path = "refund" element={<PrivacyPolicy />}/>
        <Route path = "privacy" element={<RefundPolicy />}/>
        <Route path="tutors" element={<TutorsList />} />
        <Route path="tutors/:tutorId" element={<TutorDetails />} />
        <Route path="tutors/:tutorId/:course" element={<CourseDetails />} />
        <Route path="courses" element={<CourseList />} />
        <Route path="courses/:tutorId/:course" element={<CourseDetails />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="login_via_phone" element={<PhoneAuth />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="tutor-panel/dashboard" element={<TutorDashboard />} />
        <Route path="tutor-panel/schedule" element={<TutorSchedule />} />
        <Route path="tutor-panel/history" element={<TutorHistory />} />
        <Route path="/tutor-panel/history/:tutorId/:course" element={<TutorCourseDetails />} />
        <Route path="student-panel/dashboard" element={<StudentDashboard />} />
        <Route path="student-panel/schedule" element={<StudentSchedule />} />
        <Route path="student-panel/history" element={<StudentHistory />} />
        <Route path="/student-panel/history/:tutorId/:course" element={<StudentCourseDetails />} />
         {/* Redirect any unknown route to the Home page */}
         <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AuthProvider>
  );
};

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
