import React from 'react';

const RefundPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Refund Policy</h1>
      <p>Thank you for choosing Music Seekho. This Refund Policy outlines the terms under which refunds may be issued.</p>

      <h2>1. Eligibility for Refunds</h2>
      <p>Refunds are applicable in the following scenarios:</p>
      <ul>
        <li>A class is canceled due to technical issues on our end.</li>
        <li>You are unable to access a purchased service despite multiple attempts to resolve the issue.</li>
      </ul>

      <h2>2. Non-Refundable Cases</h2>
      <p>Refunds will not be issued for:</p>
      <ul>
        <li>Partially used services or classes.</li>
        <li>Cancellations made less than 24 hours before a scheduled class.</li>
      </ul>

      <h2>3. Refund Process</h2>
      <p>
        To request a refund, email us at{' '}
        <a href="mailto:refunds@musicseekho.com" style={{ color: '#007bff', textDecoration: 'none' }}>
          refunds@musicseekho.com
        </a>{' '}
        within 7 days of the transaction. Provide your transaction ID and reason for the refund.
      </p>

      <h2>4. Timeline for Refunds</h2>
      <p>Refunds, if approved, will be processed within 5-7 business days.</p>

      <p>
        For questions, contact us at{' '}
        <a href="mailto:support@musicseekho.com" style={{ color: '#007bff', textDecoration: 'none' }}>
          support@musicseekho.com
        </a>.
      </p>
    </div>
  );
};

export default RefundPolicy;
